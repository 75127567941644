import "./order-lists.scss";
import {canPermission} from "../../../utils/users";

class ActionDetailController{
    constructor(API,ToastService,$state, $translate, $stateParams, $auth, $rootScope,$window, DialogService) {
        'ngInject';

        this.ToastService = ToastService;
        this.state = $state;
        this.$translate = $translate;
        this.$window = $window;
        this.$auth = $auth;
        this.$stateParams = $stateParams;
        this.$rootScope = $rootScope;
        this.API = API;
        this.DialogService = DialogService;
        this.branch = {}
        this.statistics = null
        this.canPermission = canPermission;

    }

    $onInit() {
        this.preferredProductCode = this.$rootScope.companyInfo['preferred-product-code'];
        if (undefined !== this.actionId){
            this.getDetail(this.actionId)
        }
    }

    getDetail(id){
        this.API.all("action/"+id)
          .doGET()
          .then((response) => {
              this.statistics = response.data.list.statistic
              this.actionObject = response.data.list;

              if (this.actionObject.branchId){
                  this.API.all("settings/branch/" + this.actionObject.branchId).doGET()
                      .then((response) => {
                          if (204 === response.response.status) return;
                          this.branch = response.data.list

                      })
              }
          });
    }

    downloadProcessDocument(document, processId) {
        this.API.all(`action-process/document/${processId}`)
            .customGET(null,{
                document:document
            })
            .then((response) => {
                const printWindow = window.open('', '_blank');
                printWindow.document.write(response.data.document);
                printWindow.document.close();
                printWindow.onload = function() {
                    printWindow.print();
                    printWindow.close();
                };
            })
    }
    createReturnedOffer(partialReturned, note = '', offerId = null) {
        let d = this.DialogService;
        this.convertToOffer = this.actionObject.transactionId;
        let convertToOffer =  this.actionObject.transactionId;
        if (offerId){
            this.convertToOffer = '_'
        }
        if (!this.convertToOffer) {
            this.ToastService.showHtml('warn', this.$translate.instant('RETURN_NUMBER_REQUIRED'))
            return;
        }

        this.DialogService.fromTemplateV2(
            './views/angular/app/components/invoices/partials/dialog/return-confirm.html',
            {
                onShowing: function (scope, element, options, controller) {
                    scope.DialogService = d;
                    scope.transactionId = convertToOffer;
                    scope.cancel = ()=>{
                        scope.DialogService.$mdDialog.hide(false)
                    }
                    scope.ok = ()=>{
                        scope.DialogService.$mdDialog.hide(true)
                    }
                }
            }
        ).then(ok =>{
            if (ok){
                this.API.all("action-process/returned/" + this.convertToOffer)
                    .customDELETE(null, {
                        partialReturned: partialReturned ? 1 :0,
                        note: note,
                        offerId: offerId
                    }).then((response) => {
                    if (response.response.status === 207) {
                        this.ToastService.showHtml('success', this.$translate.instant('RETURN_CREATED'))
                    }
                })
            }
        })
            .catch(err => {
                console.log(err)
            });
    }


  printActionDocument(actionId) {
    this.API.all("action/barcode")
      .post({actionId: actionId})
      .then((response) => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(response.data.document);
        printWindow.document.close();
        printWindow.onload = function() {
          printWindow.print();
          printWindow.close();
        };
      })
  }

  downloadActionDocument(actionId){
    var parent = this;
    var options = {
      controller        : 'PrintPreviewDialogController',
      controllerAs      : 'vm',
      locals            : {
        actionId     : actionId
      },
      bindToController  : true,
      multiple          : true,
      onShowing         : function (scope, element, options, controller){
        parent.API.all("action/barcode")
          .post({actionId: actionId})
          .then((response) => {
            //window.open(window.__env.apiUrl + "/api/action/exportPdf/" + response.fileName + "?token=" + window.localStorage.satellizer_token, '_blank');
            parent.pdfUrl = window.__env.apiUrl + "/api/action/exportPdf/" + response.fileName + "?token=" + window.localStorage.satellizer_token;
          },function(response) {
            parent.DialogService.hide();
          });
      },
    };

    var customerDialogPromise = this.DialogService.fromTemplateV2(
      './views/angular/dialogs/printPreview/printPreview.dialog.html',
      options
    );
    customerDialogPromise.catch(err=>{
      console.log(err)
    })


  }

  getActionBarcode(actionId) {
    var reqData = {
      actionId: actionId,
    };
    this.API.all("action/barcode")
      .post(reqData)
      .then((response) => {
        //window.open(window.__env.apiUrl + "/api/action/exportPdf/" + response.fileName + "?token=" + window.localStorage.satellizer_token, '_blank');
        parent.pdfUrl = window.__env.apiUrl + "/api/action/exportPdf/" + response.fileName + "?token=" + window.localStorage.satellizer_token;
      },function(response) {
        parent.DialogService.hide();
      });
  }


}
ActionDetailController.$inject = ["API", "ToastService", "$state", "$translate", "$stateParams", "$auth", "$rootScope", "$window", "DialogService"];
const ActionDetailComponent = {
    templateUrl: './views/angular/app/components/order-lists/partials/action-detail.component.html',
    controller: ActionDetailController,
    controllerAs: 'vm',
    bindings: {
        actionId: '<'
    }
};
export {ActionDetailComponent, ActionDetailController}
