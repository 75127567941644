import angular from "angular";
import "./critical.scss";

/*import "./material/project.scss";
import "./material/fonts.scss";
import "./material/page.scss";
import "./material/utilities.scss";
import "./material/transitions.scss";
*/
import "./app/pages/dashboard_index_page/dashboard_index_page.scss";
import "./app/pages/header/header.scss";
import "./app/pages/footer/footer.scss";
import "./app/pages/forgot-password/forgot-password.scss";
import "./app/pages/invoices/invoices.scss";
import "./app/pages/landing/landing.scss";
import "./app/pages/login/login.scss";
import "./app/pages/register/register.scss";
import "./app/pages/reset-password/reset-password.scss";
import "./app/pages/sidebar/sidebar.scss";

import "angular-ui-grid/ui-grid.css";

// vendor styles
//import "angular-material/angular-material.css";


import './index.modules';

import './index.run';

import './index.config';

// Default environment variables
var __env = {};

// Import variables if present
if (window) {
  Object.assign(__env, window.__env);
}
//angular.constant('__env', __env);

import './index.filters';

import './index.components';

import './index.directives';

import './index.services';
