import {RouteController} from "../app/components/app-root/app-root.component";

function RoutesConfig($stateProvider, $urlRouterProvider) {
  'ngInject';

  let getView = (viewName) => {
    return `./views/angular/app/pages/${viewName}/${viewName}.page.html`;
  };

  let getHelperView = (viewName) => {
    return `./views/angular/app/pages/${viewName}.page.html`;
  };

  $urlRouterProvider.otherwise('/');

  /*
      data: {auth: true} would require JWT auth
      However you can't apply it to the abstract state
      or landing state because you'll enter a redirect loop
  */

  $stateProvider
    .state('app', {
      abstract: true,
      data: {},
      views: {
        'shortcut@': {
          templateUrl: getView('shortcut')
        },
        'sidebar@': {
          templateUrl: getView('sidebar')
        },
        footer: {
          templateUrl: getView('footer')
        },
        main: {}
      }
    })
    .state('app.landing', {
      url: '/',
      views: {
        'shortcut@': '',
        'sidebar@': '',
        'main@': {
          templateUrl: getView('login')
        }
      }
    })
    .state('app.authorize', {
      url: '/authorize?client_id&token',
      views: {
        'shortcut@': '',
        'sidebar@': '',
        'main@': {
          templateUrl: getView('authorize')
        }
      }
    })
    .state('app.login', {
      url: '/login',
      views: {
        'shortcut@': '',
        'sidebar@': '',
        'main@': {
          templateUrl: getView('login')
        }
      }
    })
    .state('app.forgot_password', {
      url: '/forgot-password',
      views: {
        'shortcut@': '',
        'sidebar@': '',
        'main@': {
          templateUrl: getView('forgot-password')
        }
      }
    })
    .state('app.reset_password', {
      url: '/reset-password/:email/:token',
      views: {
        'shortcut@': '',
        'sidebar@': '',
        'main@': {
          templateUrl: getView('reset-password')
        }
      }
    })
    .state('app.dashboard', {
      url: '/dashboard',
      views: {
        'main@': {
          templateUrl: getView('dashboard_index_page')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0
      }
    })
    .state('app.location_management', {
      url: '/location-management?tab&locationId&barcode&processId',
      views: {
        'main@': {
          templateUrl: getView('location-management')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        locationId:null,
        processId:null,
        barcode:null,
        shortcutNames: {
          '0': 'LOCATIONS',
          '1': 'SHELVES',
          '2': 'SHELF_DETAIL',
          '3': 'SHELF_UP',
          '4': 'SHELF_CHANGE',
          '5': 'LOCATION_STOCKTAKING',
          '6': 'WAREHOUSE_LAYOUT_GUIDE',
        },
        icon: 'location_on'
      }
    })
    .state('app.return-management', {
      url: '/return-management?tab',
      views: {
        'main@': {
          template: '<return-management></return-management>'
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'RETURN',
          '1': 'RETURN_RECEIVING',
        },
        icon: 'assignment_return'
      }
    })
    .state('app.stock_organization', {
      url: '/stock-organization?tab',
      views: {
        'main@': {
          templateUrl: getView('stock-organization')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'CATEGORY_TREE',
          '1': 'BRAND_MANAGEMENT',
          '2': 'TAG_MANAGEMENT',
        },
        icon: 'category'
      }
    })
    .state('app.stock_management', {
      url: '/stock-management?tab&term&product_id&product_code',
      views: {
        'main@': {
          templateUrl: getView('stock-management')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'STOCK_LIST',
          '1': 'CREATE_STOCK',
          '2': 'STOCK_CARD',
        },
        icon: 'warehouse'
      }
    })
    .state('app.invoices', {
      url: '/invoices?tab&sipNo',
      views: {
        'main@': {
          templateUrl: getView('invoices')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0
      }
    })
    .state('app.offer', {
      url: '/offer?tab&offerId&offerType&barcode',
      views: {
        'main@': {
          templateUrl: getView('offer')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        offerId:null,
        offerType:null,
        barcode:null,
        shortcutNames: {
          '0': 'SALES_OFFER',
          '1': 'PURCHASE_OFFERS',
        },
        icon: 'shopping_cart'
      }
    })
    .state('app.get_invoices', {
      url: '/invoices/:sipNo',
      views: {
        'main@': {
          templateUrl: getView('invoices')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0
      }
    })
    .state('app.invoices.edit', {
      url: '/edit/:sipNo',
      params: {
        sipNo: null
      },
      views: {
        'shortcut@': {
          templateUrl: getView('shortcut')
        },
        'sidebar@': {
          templateUrl: getView('sidebar')
        },
        'main@': {
          templateUrl: getHelperView('invoices/edit')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0
      }
    })
    .state('app.invoices.balance', {
      url: '/balance/:sipNo',
      params: {
        sipNo: null
      },
      views: {
        'shortcut@': {
          templateUrl: getView('shortcut')
        },
        'sidebar@': {
          templateUrl: getView('sidebar')
        },
        'main@': {
          templateUrl: getHelperView('invoices/balance')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0
      }
    })
    .state('app.integration', {
      url: '/integration?tab',
      views: {
        'main@': {
          templateUrl: getView('integration')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'SIPARISLER',
          '1': 'STOCK',
        },
        icon: 'cloud_sync'
      }
    })
    .state('app.cargo', {
      url: '/cargo?tab',
      views: {
        'main@': {
          templateUrl: getView('cargo')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'STATUS',
          '1': 'SHIPPING_ITEMS',
          '2': 'DONE_PROCESS',
          '3': 'CARGO_PROCESSES',
          '4': 'PRINT_UNIQUE_CARGO_BARCODE',
          '5': 'PRINT_CARGO_BARCODE',
          '6': 'PRINT_CARGO_REPORT',
        },
        icon: 'local_shipping'
      }
    })
    .state('app.users', {
      url: '/users?tab',
      views: {
        'main@': {
          templateUrl: getView('users')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'USERS',
          '1': 'ROLES',
        },
        icon: 'engineering'
      }
    })
    .state('app.order_lists', {
      url: '/order-lists?actionNu&tab',
      views: {
        'main@': {
          templateUrl: getView('order-lists')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'STOCK_MOVEMENTS',
          '1': 'CREATE_STOCK_MOVEMENTS',
          '2': 'CHANNEL_ORDERS',
          '3': 'STOCK_MOVEMENT_SUMMARY',
          '4': 'PRODUCT_SALES_REPORT',
          '5': 'TURNOVER_REPORT',
        },
        icon: 'move_down'
      }
    })
    .state('app.issues', {
      url: '/issues?tab&id&screen',
      views: {
        'main@': {
          templateUrl: getView('issues')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'ISSUES',
          '1': 'ISSUE',
          '2': 'SHELF_EXIT_ISSUE_CREATE',
          '3': 'SHELF_ENTRY_ISSUE_CREATE',
          '4': 'FAULTY_ISSUE_CREATE',
        },
        icon: 'format_list_bulleted'
      }
    })
    .state('app.customers', {
      url: '/customers?tab',
      views: {
        'main@': {
          templateUrl: getView('customers')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'CREATE_CUSTOMER',
          '1': 'CUSTOMERS',
        },
        icon: 'people'
      }
    })
    .state('app.receiving', {
      url: '/receiving?tab',
      views: {
        'main@': {
          templateUrl: getView('receiving')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'PURCHASE_RECEIVING',
          '1': 'PRODUCTION_RECEIVING',
          '2': 'Depolar Arası Transfer Giriş Mal Kabul',
          '3': 'Serbest Giriş Mal Kabul'
        },
        icon: 'call_received'
      }
    })
    .state('app.settings', {
      url: '/settings?tab',
      views: {
        'main@': {
          templateUrl: getView('settings')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'CHANNEL_SETTINGS',
          '1': 'ORDER_STATUSSES',
          '2': 'PAYMENT_TYPES',
          '3': 'AUTOMATIC_TRANSFER_SETTINGS',
          '4': 'CRON_JOB',
          '5': 'COMPANY_SETTINGS',
          '6': 'EXCHANGE_RATE',
        },
        icon: 'settings'
      }
    })
    .state('app.account-settings', {
      url: '/account-settings?tab',
      views: {
        'main@': {
          templateUrl: getView('account-settings')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'ACCOUNT_SETTINGS',
        },
        icon: 'settings'
      }
    })
    .state('app.batch-operations-byexcel', {
      url: '/batch-operations?tab',
      views: {
        'main@': {
          templateUrl: getView('batch-operations-byexcel')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0
      }
    })
    .state('app.batch-operations-for-tsoft-byexcel', {
      url: '/batch-operations-for-tsoft',
      views: {
        'main@': {
          templateUrl: getView('batch-operations-for-tsoft-byexcel')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0
      }
    })
    .state('app.production-for-duvee', {
      url: '/production-stream',
      views: {
        'shortcut@': '',
        'sidebar@': '',
        'mainfullwidth@': {
          templateUrl: getView('production-for-duvee')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0
      }
    })
    .state('app.create-production-action', {
      url: '/create-production-action',
      views: {
        'main@': {
          templateUrl: getView('create-production-action')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'PRODUCTION_MOVEMENTS',
        },
        icon: 'mediation'
      }
    })
    .state('app.orders-prepare', {
      url: '/orders-prepare?tab',
      views: {
        'main@': {
          templateUrl: getView('orders-prepare')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'ORDERS_PICKING',
          '1': 'HANDLING',
          '2': 'PACKAGING',
          '3': 'CONTROL',
          '4': 'MISSING_REPORT',
          '5': 'ACCOUNTING_TRANSFERS',
        },
        icon: 'precision_manufacturing'
      }
    })
    .state('app.notification', {
      url: '/notification?tab',
      views: {
        'main@': {
          templateUrl: getView('notification')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'NOTIFICATIONS',
          '1': 'DOWNLOADS',
        },
        icon: 'notifications'
      }
    })
    .state('app.about', {
      url: '/about?tab',
      views: {
        'main@': {
          templateUrl: getView('about')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'RELEASE_NOTES',
          '1': 'SUPPORT',
          '2': 'ABOUT_US',
        },
        icon: 'info'
      }
    })
    .state('app.test', {
      url: '/test?tab',
      views: {
        'main@': {
          templateUrl: getView('test')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0
      }
    })
    .state('app.reports', {
      url: '/reports?tab',
      views: {
        'main@': {
          templateUrl: getView('reports')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'REPORTS',
        },
        icon: 'analytics'
      }
    })

    .state('app.offer-recipe', {
      url: '/offer-recipe?tab',
      views: {
        'main@': {
          templateUrl: getView('offer-recipe')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        shortcutNames: {
          '0': 'OFFER_RECIPE',
          '1': 'RECIPE_RECEIVING',
        },
        icon: 'factory'
      }
    })

    .state('app.recipe', {
      url: '/recipe/?tab&productId',

      views: {
        'main@': {
          templateUrl: getView('recipe')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        productId:null,
        tab: 0,
        shortcutNames: {
          '0': 'OFFER_RECIPE',
          '1': 'RECIPE_RECEIVING',
        },
        icon: 'calculate'
      }
    })
    .state('app.admin', {
      url: '/admin?tab',
      views: {
        'main@': {
          templateUrl: getView('admin')
        }
      },
      reloadOnSearch: false,
      data: {
        auth: true,
        tab: 0,
        icon: 'support_agent'
      }
    })
  ;
}

RoutesConfig.$inject = ["$stateProvider", "$urlRouterProvider"];

export {RoutesConfig};

