import {introductionVideos, openIntroductionVideo} from "../../../utils/introduction-videos";
import "../cargo/cargo.scss";
import Cookies from "js-cookie";
import moment from "moment/moment";
import {list} from "postcss";
import {getUserInfo} from "../../../utils/users";

class ShippingItemCounterController {
    constructor(API, ToastService, DialogService, $state, $location, $window, $sce, $timeout, $scope, $rootScope, $mdBottomSheet) {
        "ngInject";

        this.API = API;
        this.window = $window;
        this.$scope = $scope;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$location = $location;
        this.sce = $sce
        this.$timeout = $timeout
        this.$rootScope = $rootScope;
        this.falseBeep = new Audio('./files/error.wav');
        this.trueBeep = new Audio('./files/success.wav');
        this.$mdBottomSheet = $mdBottomSheet;


        this.userInfo = getUserInfo();
        this.currentUserId = this.userInfo.uid;

        this.shippingList = [];
        this.shippingDetail = [];
        this.shippingListCount = 0;
        this.shippingListScreen = 'list';
    }

    $onInit() {
        let parent = this;
        parent.$scope.$on('prepareEvent', function (event, data) {
            if (data.screen === 'shippingCounter') {
            }
        })
    }


    getDetail(barcode) {
        this.API.all(`action-process/shipment/_`)
            .doGET(null, {
                counting: 1,
                term: barcode,
            })
            .then((response) => {
                this.barcode = null;
                this.focusInput('productBarcode');
                if (response.response.status !== 200) {
                    return;
                }
                this.shippingDetail = response.data.list
                this.focusInput('productBarcode');

            });
    }

    barcodeCheck(barcode) {
        this.API.all(`action-process/shipment/${barcode}/approveCounting`)
            .customPUT({
                counting: 1,
                term: barcode,
            })
            .then((response) => {

                this.barcode=null;
                if (response.response.status !== 200) {
                    return;
                }
                this.trueBeep.play();
                this.barcode = null
                this.focusInput('productBarcode');
                this.shippingDetail = response.data.list.result
            })
            .catch(()=>{
                this.focusInput('productBarcode');
            });
    }


    showOrderDetail(id) {
        let options = {
            controller: 'InvoicesController',
            controllerAs: 'vm',
            locals: {
                orderId: id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getDetailOrderId(controller.orderId)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/invoices/partials/invoices-detail.component.html',
            options
        )
    }

    showActionDetail(action_id) {
        this.selectedDetailActionId = action_id;
        let options = {
            controller: 'ActionDetailController',
            controllerAs: 'vm',
            locals: {
                id: action_id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getDetail(controller.id)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/order-lists/partials/action-detail.component.html',
            options
        )
    }

    focusInput(id){
        document.getElementById(id)?.focus();

    }
    showCameraView() {
        var parent = this;
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
            controller: "BottomCameraController",
            controllerAs: "vm",
            bindToController: true,
            locals: {}
        }).then(response => {
            parent.barcode = response;
        })
            .catch(function (error) {
                console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
            });
    }
}

ShippingItemCounterController.$inject = ["API", "ToastService", "DialogService", "$state", "$location", "$window", "$sce", "$timeout", "$scope", "$rootScope", "$mdBottomSheet"];
const ShippingItemCounterComponent = {
    templateUrl: "./views/angular/app/components/cargo/partials/shipping-item-counter-process.component.html",
    controller: ShippingItemCounterController,
    controllerAs: "vm",
    bindings: {},
};
export {ShippingItemCounterComponent, ShippingItemCounterController}
