import {FocusInputDirective} from './directives/focusInput/focusInput.directive';
import {PermissionDirective} from './directives/permission/permission.directive';
import {PasteEventDirective} from './directives/pasteEvent/pasteEvent.directive';

angular.module('app.directives')
    .directive('focusInput', FocusInputDirective)
    .directive('permission', PermissionDirective)
    .directive('ppPaste', PasteEventDirective)
    .directive("fileRead", [function () {
        return {
            restrict: 'A',
            scope: {
                fileRead: '=',
                fileReadCallBackFun: '&',
            },
            link: function (scope, element, attributes) {
                element.bind("change", function (changeEvent) {
                    scope.$apply(function () {
                        scope.fileRead = changeEvent.target.files;
                        //todo: fonksiyon gönderirken hangi isimde argümanın varsa onu işaretlemen gerekiyormuş "file"
                        scope.fileReadCallBackFun({file: changeEvent.target.files})
                    });
                });
            }
        }
    }])
    .directive('myEnter', function () {
        return function (scope, element, attrs) {
            element.bind("keydown keypress", function (event) {
                if (event.which === 13) {
                    scope.$apply(function () {
                        scope.$eval(attrs.myEnter);
                    });

				event.preventDefault();
			}
		});
	};
})
    .directive('focusScroll',function (){
      return function (scope, element, attrs) {
        element[0].addEventListener('focusout', function () {
          // todo: 'hidden' koyulunca sayfanın scrool olmaması kontrol edilecek
          document.body.style = 'overflow:auto';
        })
        element[0].addEventListener('focusin', function () {
          document.body.style = 'overflow: auto';
        })
      }
    })
    .directive('setFocus', function () {
      return {
        link: function (scope, element, attrs) {
          element.bind('click', function () {
            document.querySelector('#' + attrs.setFocus).focus();
          })
        }
      }
    })
  /*
    .directive('focusToInput',function (){
      return function (scope,element,attrs){
          element.focus()
      }
    })
   */
    .directive('mySticky', function ($mdSticky) {
        return {
            restrict: 'E',
            template: `<div layout="row" style="padding: 0 5px;width: 100%;" id="sticky">
                <md-icon style="margin:20px 13px 0 2px; font-size:xx-large" class="material-symbols-outlined">barcode</md-icon>
                <md-input-container my-enter="vm.prepareListItem()" class=""
                                    flex ng-if="vm.actionPrepareProductListSelect.barcode !== '' && vm.actionPrepareProductListSelect.barcode !== undefined">
                    <label>{{ "BARCODE" | translate }}</label>
                    <input focus-to-input="" type="text" ng-model="vm.productBarcode" />
                </md-input-container>

                <md-input-container layout="row" layout-align="end center">
                    <md-button class="md-raised md-primary md-button md-ink-ripple mini-btn"
                               ng-click="vm.actionPrepareListSection[vm.orderListType]='select';vm.actionList()">
                        <md-icon style="color: #fff" class="material-icons">arrow_back</md-icon>
                        <span hide-xs="">{{ "BACK" | translate }}</span>
                    </md-button>
                </md-input-container>
            </div>`,
            link: function (scope, element) {
                $mdSticky(scope, element);
            }
        }
    })
    .directive('focusMe', function () {
        return {
            scope: {trigger: '=focusMe'},
            link: function (scope, element) {
                scope.$watch('trigger', function (value) {
                    if (value === true) {
                        element[0].focus();
                        scope.trigger = false;
                    }
                });
            }
        };
    })
    .directive('disableVirtualKeyboard', function () {
        return {
            link: function (scope, element) {
                element[0].addEventListener('focusout', function () {
                    element[0].setAttribute('readonly', true)
                })
                element[0].addEventListener('focusin', function () {
                    element[0].removeAttribute('readonly')
                })
            }
        };
    })
    .directive('ppIcon', function() {
      return {
        restrict: 'E',
        template: '<md-icon ng-transclude class="material-symbols-outlined" style="color:#aaa;font-size:21px""></md-icon>',
        transclude: true
      };
    })
    .directive('allowOnly',function (){
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                let allowedChars = attrs.allowOnly;
                element.on('input', function() {
                    let inputValue = element.val();
                    let sanitizedValue = inputValue.replace(new RegExp('[^' + allowedChars + ']', 'g'), '');
                    if (inputValue !== sanitizedValue) {
                        element.val(sanitizedValue);
                        scope.$apply(function() {
                            scope.inputValue = sanitizedValue;
                        });
                    }
                });
            }
        }
    })
