import "./invoices.scss";
import {getUserInfo,canPermission} from "../../../utils/users";

class InvoicesController {
  constructor(
    API,
    ToastService,
    $timeout,
    i18nService,
    $auth,
    $state,
    DialogService,
    UserService,
    $templateCache,
    $scope,
    $stateParams,
    $translate,
    $location,
    $rootScope,
    $mdBottomSheet
  ) {
    "ngInject";
    this.API = API;
    this.ToastService = ToastService;
    this.DialogService = DialogService;
    this.$timeout = $timeout;
    this.$mdBottomSheet = $mdBottomSheet;

    this.i18nService = i18nService;
    this.state = $state;
    this.$auth = $auth;
    this.UserService = UserService;
    this.$templateCache = $templateCache;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$translate = $translate;
    this.$location = $location;
    this.$rootScope = $rootScope;

    this.responsibleUsers = this.UserService.getResponsibleUsers();
    this.video = undefined;
    this.channelId = undefined;
    this.invoiceDate = null
    this.invoiceValidityDate = null;
    this.takeBuyingProduct = [] ;
    this.productNote = [] ;
    this.searchingProduct = null ;
    this.buyingProductQuantity = [];
    this.locations = null
    this.orderDetail = {};
    this.branches = [];
    this.buyingProductQuantityInList = [];
    this.missingProducts = null
    this.buyingProductNewPrice = [];
    this.buyingProductsInArr = [] ;
    this.selectedLots = [];
    this.basketProductsArr = [];
    this.userInfo = getUserInfo();
    this.filterCollapsed = true;
    this.show_vat_included = true;
    this.canPermission = canPermission;
    this.ownerUsers = this.UserService.getAllUsers();
    this.isOrderCurrency = true;
    this.buttonText = this.isOrderCurrency ? this.$translate.instant('SELECTED_CURRENCY') : this.$translate.instant('ORDER_CURRENCY');

    this.column = 'ts_order_date';
    this.sortMode = 'desc';
    this.sortModeStr = 'arrow_drop_down';
    this.timeRange = 'lastWeek';
    this.page = 1;
    this.perPage = 10;

    var parent = this;
    $translate([
      "SELECT_STATUSSES_ORDER",
      "SEARCH_STATUS",
      "REMOVE_STATUS",
      "SELECT_ALL",
      "WAS_SELECT_STATUS",
    ]).then(function (translations) {
      parent.dropdownStatsTranslationsText = {
        buttonDefaultText: translations.SELECT_STATUSSES_ORDER + ".",
        searchPlaceholder: translations.SEARCH_STATUS,
        uncheckAll: translations.REMOVE_STATUS,
        checkAll: translations.SELECT_ALL,
        dynamicButtonTextSuffix: " " + translations.WAS_SELECT_STATUS + ".",
      };
    });

  }

  $onInit() {
    if (localStorage.buyingList != null){
      this.localStoragebuyingList = JSON.parse(localStorage.getItem('buyingList'))
      this.localStoragebuyingList.products.map((item,index) => {
        this.buyingProductsInArr.push(item.product);
        this.productNote[index] = item?.note
        //  this.buyingProductQuantity = this.buyingProductQuantityInList[index]
        this.buyingProductQuantityInList[index] = item.quantity
        this.discountPercent = item.discount
        this.buyingProductNewPrice[index] = item?.newPrice
      })
      /*
      companyLabel          : this.createCompanyLabel,
          invoiceNum            : this.createInvoiceNum,
          invoiceDate           : this.invoiceDate,
          invoiceNote           : this.createInvoiceNote, */
      this.buyingLocation = this.localStoragebuyingList.locationId
      this.createActionTypeId = this.localStoragebuyingList.actionId
      this.createCompanyId = this.localStoragebuyingList.companyId;
      this.createCompanyLabel = this.localStoragebuyingList.companyLabel
      this.createInvoiceNum = this.localStoragebuyingList.invoiceNum
      this.invoiceDate = this.localStoragebuyingList.invoiceDate
      this.invoiceValidityDate = this.localStoragebuyingList.invoiceValidityDate
      this.createInvoiceNote = this.localStoragebuyingList.invoiceNote
      this.createCargoFirm = this.localStoragebuyingList.cargoFirm
      this.totalBuyingPrice = this.localStoragebuyingList.totalPrice
      this.totalBuyingPriceVatIncluded = this.localStoragebuyingList.totalPriceVatIncluded
    }
    this.sipNo = this.$stateParams.sipNo;
    this.activeOrderlineControl = false; // false
    this.balancedProducts = [];
    this.product_is_stock = [];
    this.cb1 = [];

    this.orders = [];
    this.video = 'invoices';

    this.selectedTabIndex = _.isEmpty(this.state.params.tab) ? 0 : this.state.params.tab;

    this.API.all("shipment")
        .customGET()
        .then((response) => {
          this.buyingCargoFirms = response.data.list
        })

    this.API.all("location")
        .customGET()
        .then((response) => {
          if (response.errors === false) {
            this.locations = response.data.location;
          }
        });
    this.API.all("settings/get-channels")
      .post()
      .then((response) => {
        this.channels = response.data.channels;
        this.getStatsByChannelId(this.channelId);
      });

    this.changeTimeRange();
  }

  filter(page = 1, filters, perPage = this.perPage) {
    this.page = page;
    this.perPage = perPage;

    let parent = this;
    let filterArr = {
      startDate: this.startDate,
      endDate: this.endDate,
      orderNo: this.orderNo,
      issueId: this.issue,
      balanced: this.balanced,
      channelId: this.channelId,
      selectedStats: this.transferSettingsSelectedStats,
      notTransfered: this.erpNotTransfered,
      selectedResponsibleUser: this.selectedResponsibleUser,
      accountingProcess: this.accountingProcess,
      actionType: this.actionType,
      customerCode: this.customerCode,
      customerName: this.customerName,
      minPrice: this.minPrice,
      maxPrice: this.maxPrice,
      createUserId: this.createUserId,
      excelExport: false,

      page: page,
      perPage: perPage,
      column: this.column,
      sortMode: this.sortMode
    };

    this.colorVal = "orangeBg";
    this.disabled = true;
    this.order_filter = filterArr;

    this.API.all("invoices/filter")
      .post(this.order_filter)
      .then((response) => {
        parent.orders = response.data.orders;
        parent.total = response.data.orders.total;
        parent.page = response.data.orders.current_page;

        parent.colorVal = "whiteBg";
        parent.disabled = false;
      })
      .catch((response) => {
        parent.disabled = false;
        if (response.status === 422) {
          this.colorVal = "whiteBg";
          this.ToastService.success(response.data.message);
        }
      });
  }

  onSelectTab(obj){
    this.$location.search(obj);
  }

  transfer(sipNo) {
    this.API.all("integration/exportOrder")
        .post({sip_no: sipNo})
        .then((response) => {
          if (response !== null && response.type == "redirect") {
            this.$auth.logout();
            this.state.go("app.login");
          }

          if (response.error == false) {
            this.ToastService.success(
                this.$translate.instant("ORDER_TRANSFERED_ERP")
            );
          }

          if (response.error == true) {
            this.ToastService.error(
                this.$translate.instant("ORDER_NOT_TRANSFERED_ERP")
            );
          }
        })
        .catch(function () {

        });
  }

  createAction(orderNo, packageNo, channelId) {
    var self_ = this;
    this.API.all("action/saveFromOrder")
        .post({orderNo, packageNo, channelId})
        .then((response) => {
          this.ToastService.success(this.$translate.instant('CREATED_FOR_ORDER', { orderNo: orderNo }));
        })
        .catch(function () {
        });
  }

  changeSortMode() {
    this.sortMode = this.sortMode === 'asc' ? 'desc': 'asc';
    if (this.sortMode === 'asc') {
      this.sortModeStr = "arrow_drop_up";
    }
    if (this.sortMode === 'desc') {
      this.sortModeStr = "arrow_drop_down";
    }
    this.filter(1);
  }

  excelExport(excelBalancedProduct = true, fullExcelReport = false) {
    let filter = {
      startDate: this.startDate,
      endDate: this.endDate,
      orderNo: this.orderNo,
      customerCode: this.customerCode,
      customerName: this.customerName,
      balanced: this.balanced,
      channelId: this.channelId,
      selectedStats: this.transferSettingsSelectedStats,
      notTransfered: this.erpNotTransfered,
      selectedResponsibleUser: this.selectedResponsibleUser,
      excelExport: excelBalancedProduct,
      fullExcelReport: fullExcelReport,
    };

    this.colorVal = "orangeBg";
    this.disabled = true;

    this.API.all("invoices/filter")
      .post(filter)
      .then((response) => {
        window.location.assign(window.__env.apiUrl+"/api/stock/exportExcel/"+response.data.fileName+"?token="+window.localStorage.satellizer_token);

        let parent = this;
        parent.orders = response.data.orders;
        parent.total = response.data.orders.total;
        parent.current_page = response.data.orders.current_page;
        parent.per_page = response.data.orders.per_page;
        parent.last_page = response.data.orders.last_page;
        parent.prev_page_url = response.data.orders.prev_page_url;
        parent.next_page_url = response.data.orders.next_page_url;

        this.empty_desc = true;
        this.colorVal = "whiteBg";
        this.disabled = false;
        this.ToastService.success(this.$translate.instant("DOWNLOADING"));
      })
      .catch((response) => {
        if (response.status === 422) {
          this.disabled = false;
          this.colorVal = "whiteBg";
          this.ToastService.success(response.data.message);
        }
      });
  }

  getStatsByChannelId(channelId) {
    let reqObject = {
      channelId: channelId,
    };
    this.transferSettingsSelectedStats = [];
    this.API.all("settings/get-stats")
      .post(reqObject)
      .then((response) => {
        this.stats = response.data.statsArray[channelId];
      });
  }

  clearInvoiceSearch() {
    this.startDate = null;
    this.endDate = null;
    this.selectedResponsibleUser = null;
    this.channelId = null;
    this.orderNo = null;
    this.transferSettingsSelectedStats = null;
    this.balanced = null;
    this.erpNotTransfered = null;
    this.customerCode = null;
    this.customerName = null;
    this.timeRange = 'lastWeek';
    this.changeTimeRange();
  }

  getDetailOrderId(id){
    return  this.API.all("order/detail")
        .post({
          orderId: id,
        })
        .then((response) => {
          this.orderDetail = response.data;
        });
  }

  showDetail(id) {
    this.selectedDetailId = id;
    let options = {
      controller: 'InvoicesController',
      controllerAs: 'vm',
      locals: {
        orderId: id
      },
      bindToController: true,
      onShowing: function (scope, element, options, controller) {
        controller.getDetailOrderId(controller.orderId)
      },
    }
    this.DialogService.fromTemplateV2(
        './views/angular/app/components/invoices/partials/invoices-detail.component.html',
        options
    )
  }

  getShipmentDetail(id){
    this.API.all("action/shipment")
      .post({
        orderId: id,
      })
      .then((response) => {
        this.warehouseCheck = response.data.warehouseCheck;
        this.actionData = response.data.actionData;
      });
  }

  showShipmentDetail(id) {
    this.selectedDetailOrderId = id;
    let options = {
      controller: 'InvoicesController',
      controllerAs: 'vm',
      locals: {
        orderId: id
      },
      bindToController: true,
      onShowing: function (scope, element, options, controller) {
        controller.getShipmentDetail(controller.orderId)
      },
    }
    this.DialogService.fromTemplateV2(
      './views/angular/app/components/invoices/partials/invoices-shipment-detail.component.html',
      options
    )
  }

  createReturnedOffer(partialReturned, note = '', offerId = null) {
    let d = this.DialogService;
    this.convertToOffer = this.orderDetail.orderDetail.ts_order_code;
    let convertToOffer = this.orderDetail.orderDetail.ts_order_code;
    if (offerId){
      this.convertToOffer = '_'
    }
    if (!this.convertToOffer) {
      this.ToastService.showHtml('warn', this.$translate.instant('RETURN_NUMBER_REQUIRED'))
      return;
    }

    this.DialogService.fromTemplateV2(
        './views/angular/app/components/invoices/partials/dialog/return-confirm.html',
        {
          onShowing: function (scope, element, options, controller) {
            scope.DialogService = d;
            scope.transactionId = convertToOffer;
            scope.cancel = ()=>{
              scope.DialogService.$mdDialog.hide(false)
            }
            scope.ok = ()=>{
              scope.DialogService.$mdDialog.hide(true)
            }
          }
        }
    ).then(ok =>{
      if (ok){
        this.API.all("action-process/returned/" + this.convertToOffer)
            .customDELETE(null, {
              partialReturned: partialReturned ? 1 :0,
              note: note,
              offerId: offerId
            }).then((response) => {
          if (response.response.status === 207) {
            this.ToastService.showHtml('success', this.$translate.instant('RETURN_CREATED'))
          }
        })
      }
    })
        .catch(err => {
      console.log(err)
    });
  }
  showBottomView(productId){
    this.$mdBottomSheet.show({
      templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
      controller: "BottomViewController",
      controllerAs: "vm",
      bindToController: true,
      locals: {
        id: productId
      }
    })
  }

  downloadPdf(orderId) {
    this.API.all('order/detail?output=pdf')
      .withHttpConfig({ responseType: 'arraybuffer' })
      .post({orderId: orderId})
      .then((response) => {
        let blob = new Blob([response.data], { type: 'application/pdf' });
        this.previewPdf(blob)
      })
  }

  previewPdf(blob) {
    var options = {
      controller        : 'PrintPreviewDialogController',
      controllerAs      : 'vm',
      //  scope             : this.$scope,
      locals            : {
        blob:blob
      },
      bindToController  : true,
      multiple          : true,
      onShowing         : function (scope, element, options, controller) {
        controller.previewBlob(blob);
      },
    };

    var customerDialogPromise = this.DialogService.fromTemplateV2(
      './views/angular/dialogs/printPreview/printPreview.dialog.html',
      options
    );
    customerDialogPromise.catch(err=>{
      console.log(err)
    })

  }

  exportOrderPdf(orderId, type) {
    //this.selectedDetailActionId = orderId;
    var options = {
      controller: 'PrintPreviewDialogController',
      controllerAs: 'vm',
      //  scope             : this.$scope,
      locals: {
        orderId: orderId,
        type: type
      },
      bindToController: true,
      multiple: true,
      onShowing: function (scope, element, options, controller) {
        controller.printOrderPdf(orderId, type)
      },
    };

    var dialogPromise = this.DialogService.fromTemplateV2(
      './views/angular/dialogs/printPreview/printPreview.dialog.html',
      options
    );
    dialogPromise.catch(err=>{
      console.log(err)
    });
  }

  changeTimeRange() {
    var now = new Date();
    switch (this.timeRange) {
      case 'lastDay':
        this.startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        this.endDate = now;
        break;
      case 'thisDay':
        this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        this.endDate = now;
        break;
      case 'lastWeek':
        this.startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        this.endDate = now;
        break;
      case 'thisWeek':
        var firstDayOfWeek = now.getDate() - now.getDay();
        this.startDate = new Date(now.setDate(firstDayOfWeek));
        this.endDate = new Date();
        break;
      case 'lastMonth':
        this.startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        this.endDate = now;
        break;
      case 'thisMonth':
        this.startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        this.endDate = new Date();
        break;
    }
  }
  changeCurrency(){
    this.isOrderCurrency = !this.isOrderCurrency;
    this.buttonText = this.isOrderCurrency ? this.$translate.instant('SELECTED_CURRENCY') : this.$translate.instant('ORDER_CURRENCY');
  }

  }
InvoicesController.$inject = ["API", "ToastService", "$timeout", "i18nService", "$auth", "$state", "DialogService", "UserService", "$templateCache", "$scope", "$stateParams", "$translate","$location","$rootScope","$mdBottomSheet"];
const InvoicesComponent = {
  templateUrl: "./views/angular/app/components/invoices/invoices.component.html",
  controller: InvoicesController,
  controllerAs: "vm",
  bindings: {},
};

export {InvoicesComponent,InvoicesController}
