class UsersandrolesController{
    constructor(){
        'ngInject';

        //
    }

    $onInit(){
    }
}

export const UsersandrolesComponent = {
    templateUrl: './views/angular/app/components/usersandroles/usersandroles.component.html',
    controller: UsersandrolesController,
    controllerAs: 'vm',
    bindings: {}
};
