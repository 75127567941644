import { getPermission } from "../../utils/users";

class PermissionController{
    constructor(UserService,$timeout, $compile){
        'ngInject';

        this.UserService = UserService;
        this.perms = {};
        this.$timeout = $timeout;
        this.$compile = $compile;
    }
}
PermissionController.$inject = ["UserService","$timeout", "$compile"];
export function PermissionDirective(){
    return {
        controller: PermissionController,
        restrict: 'A',
        priority:600,
        link: function(scope, element, attrs, controllers) {
            scope.visible = {};
            getPermission().map(permission=>{
                scope.visible[permission] = true
            })
        }
    }
}
